"use client";

import { useTranslation } from "react-i18next";
import { REGION } from "@src/constant";
import Image from "next/image";
// import { useContext } from "react";

export default function Footer() {
  const { t } = useTranslation();

  // const openContactDialog = useContext(SetContactDialogContext);

  const footerNavigation = {
    solutions: [
      { name: "ColossalAI Platform", href: "/" },
      { name: "ColossalAI", href: "https://www.colossalai.org/" },
    ],
    support: [{ name: t("home.footer.labels.pricing"), href: "#pricing" }],
    company: [
      {
        name: t("home.footer.labels.about"),
        href: "https://www.luchentech.com/",
      },
      {
        name: t("home.footer.labels.jobs"),
        href: "https://www.luchentech.com/join/",
      },
    ],
    contacts: ["业务咨询：service@luchentech.com", "联系电话：010-81377587"],
    // legal: [
    //   // { name: t("home.footer.labels.claim"), href: "#" },
    //   {
    //     name: t("home.footer.labels.service.cloud"),
    //     href: "/agreement/service",
    //   },
    //   {
    //     name: t("home.footer.labels.service.disk"),
    //     href: "/agreement/disk",
    //   },
    //   {
    //     name: t("home.footer.labels.service.hosting"),
    //     href: "/agreement/hosting",
    //   },
    //   { name: t("home.footer.labels.privacy"), href: "/agreement/privacy" },
    // ],
    social: [
      // {
      //   name: "Twitter",
      //   href: "https://twitter.com/HPCAITech?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      //   icon: (props: any) => (
      //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      //     </svg>
      //   ),
      // },
      {
        name: "GitHub",
        href: "https://github.com/hpcaitech",
        icon: (props: any) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  };
  return (
    <footer className="bg-gray-100" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        {t("home.footer.name")}
      </h2>
      <div className="mx-auto max-w-7xl px-4 pt-16 pb-8 sm:px-6 lg:px-8 lg:pt-24">
        <div>
          <div className="gap-8">
            {/* <div className="md:grid md:grid-cols-2 md:gap-8"> */}
            {/* <div>
                <h3 className="text-base font-medium text-gray-900">
                  {t("home.footer.labels.solutions")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div> */}
            {/* <div className="mt-12 md:mt-0">
                <h3 className="text-base font-medium text-gray-900">
                  {t("home.footer.labels.support")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                  <li>
                    <span
                      className="text-base text-gray-500 hover:text-gray-900 cursor-pointer"
                      onClick={openContactDialog}
                    >
                      {t("home.footer.labels.contact")}
                    </span>
                  </li>
                </ul>
              </div> */}
            {/* </div> */}
            <div className="md:grid md:grid-cols-3 md:gap-8 mt-12 md:mt-0">
              <div>
                <h3 className="text-base font-medium text-gray-900">
                  {t("home.footer.labels.company")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-base font-medium text-gray-900">
                  {/* {t("home.footer.labels.legal")} */}
                  联系我们
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.contacts.map((item, index) => (
                    <li key={index}>
                      <span
                        // href={item.href}
                        className="text-base text-gray-500"
                      >
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-base text-gray-500">微信社群</h3>
                <Image
                  className="mt-4"
                  src="/contactWechat.jpeg"
                  height="120"
                  width="120"
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* <div className="mt-12 xl:mt-0">
            <h3 className="text-base font-medium text-gray-900">
              {t("subscribeTitle")}
            </h3>
            <p className="mt-4 text-base text-gray-500">
              {t("subscribeSubtitle")}
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                {t("email")}
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:placeholder-gray-400 focus:outline-none focus:ring-blue-500"
                placeholder={t("enterEmail") as string}
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-600 to-blue-600 bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:from-blue-700 hover:to-blue-700"
                >
                  {t("subscribe")}
                </button>
              </div>
            </form>
          </div> */}
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div className="flex space-x-6 md:order-2">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
            &copy;
            {` ${new Date().getFullYear()} ${
              REGION === "cn"
                ? "北京潞晨科技有限公司 版权所有"
                : "HPC-AI Technology, Inc. All rights reserved."
            }`}
          </p>
        </div>
        {REGION === "cn" && (
          <div className="flex mt-4 text-base text-gray-400 flex-wrap">
            <span className="inline-flex items-center">
              <Image
                src={"/gongan.png"}
                className="w-4 inline-block mr-1"
                width={16}
                height={16}
                alt="国家信息安全标识"
              />
              <a
                className="hover:text-gray-500"
                rel="noreferrer"
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040233"
              >
                京公网安备 11010802040233号
              </a>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              className="hover:text-gray-500"
              rel="noreferrer"
              target="_blank"
              href="https://beian.miit.gov.cn"
            >
              京ICP备 2022025905号-1
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              className="hover:text-gray-500"
              rel="noreferrer"
              target="_blank"
              href="https://dxzhgl.miit.gov.cn/#/home"
            >
              增值电信业务经营许可证京B2-20230740
            </a>
          </div>
        )}
      </div>
    </footer>
  );
}
