"use client";

import { useTranslation } from "react-i18next";
import Card, { type FeatureCardProps, btnClass } from "./Card";
import Link from "next/link";
import { useAppDispatch, useAppSelector } from "@src/store";
import { openContactDialog } from "@src/store/common";

export default function Partners() {
  const { t } = useTranslation();

  const { userInfo } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  const contents: FeatureCardProps[] = [
    {
      title: t("home.guidance.card.invoice.title"),
      features: [
        t("home.guidance.card.invoice.feature1"),
        t("home.guidance.card.invoice.feature2"),
        t("home.guidance.card.invoice.feature3"),
      ],
      button: (
        <div
          className={btnClass}
          onClick={() => dispatch(openContactDialog(true))}
        >
          {t("home.guidance.card.invoice.buttonText")}
        </div>
      ),
    },
    {
      title: t("home.guidance.card.quickstart.title"),
      features: [
        t("home.guidance.card.quickstart.feature1"),
        t("home.guidance.card.quickstart.feature2"),
        t("home.guidance.card.quickstart.feature3"),
      ],

      button: (
        <Link className={btnClass} href="/doc/" target="_blank">
          {t("home.guidance.card.quickstart.buttonText")}
        </Link>
      ),
    },
    {
      title: t("home.guidance.card.computeResource.title"),
      features: [
        t("home.guidance.card.computeResource.feature1"),
        t("home.guidance.card.computeResource.feature2"),
        t("home.guidance.card.computeResource.feature3"),
      ],
      button: (
        <Link
          className={btnClass}
          href={userInfo ? "/console/resource" : "/account/signin"}
        >
          {t("home.guidance.card.computeResource.buttonText")}
        </Link>
      ),
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-slate-50 w-full rounded-3xl grid grid-cols-3 gap-4 px-10 py-6">
        {contents.map((content, index) => (
          <Card
            key={index}
            title={content.title}
            features={content.features}
            button={content.button}
          />
        ))}
      </div>
    </div>
  );
}
