"use client";

import { JumpIn } from "@components/common/Animation/JumpIn";
import { Image } from "@components/common";
import { useTranslation, Trans } from "react-i18next";

export default function Hero() {
  const { t } = useTranslation();
  // const hostname = window.location.hostname;

  return (
    <div className="my-6">
      {/* Hero section */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-full" />
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative sm:overflow-hidden sm:rounded-2xl">
            <div className="absolute inset-0">
              <Image
                className="h-full w-full object-cover opacity-80 "
                src="/indexBg.avif"
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gradient-to-r mix-blend-multiply" />
            </div>
            <div className="relative px-4 sm:px-6 py-24 lg:px-8">
              <JumpIn>
                <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white ">
                    <Trans
                      i18nKey="home.hero.tagline.line1"
                      components={[
                        <span key="1" className="text-red-500"></span>,
                      ]}
                    />
                  </span>
                  <span className="block text-white mt-4">
                    {t("home.hero.tagline.line2")}
                  </span>
                </h1>
              </JumpIn>
              {/* <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                <a
                  href={`https://docs.${hostname}`}
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-blue-700 shadow-sm hover:bg-blue-50 sm:px-8 mr-8"
                >
                  {t("component.common.navBar.documentation")}
                </a>
                <a
                  href={t("common.link.contact") as string}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                >
                  {t("home.hero.buttons.requestDemo")}
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
