"use client";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GpuCard from "./GpuCard";
import { getPrices, type machinePrice } from "@utils/api/resource";

export default function Pricing() {
  const { t } = useTranslation();
  const [prices, setPrices] = useState<machinePrice[]>([]);

  const initMachinePrices = async () => {
    try {
      const { machinePrices } = await getPrices();
      setPrices(machinePrices);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    initMachinePrices();
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12 ">
        <div className="text-center">
          <p className="font-bold mt-4 mb-6 tracking-tight text-gray-900 text-4xl">
            {t("home.pricing.homeTitle")}
          </p>
          <p className="mt-2 tracking-tight text-gray-500 text-2xl">
            {t("home.pricing.desc")}
          </p>
        </div>

        <div className="mt-8 grid grid-cols-3 gap-x-10 gap-y-8 justify-items-center">
          {prices.map((price) => (
            <GpuCard key={price.gpuType.gpuName} {...price} />
          ))}
        </div>
      </div>
    </div>
  );
}
